import React from "react"
import { useState } from "react"
import "./CookiesNotification.scss"
import Button from "../Button/Button"

const CookiesNotification: React.FC = () => {
  const [showCookies, setShowCookies] = useState(true)

  const handleClick = () => {
    setShowCookies(false)
  }

  return (
    showCookies && (
      <div className="cookies">
        <div className="cookies__container">
          <div className="cookies__text">
            This website utilizes cookies to enhance your browsing experience
            and deliver tailored services. By continuing to browse this site,
            you are consenting to the use of cookies as described in this
            notice.
            <br />
            <a
              href="https://wingform.com/assets/docs/Cookie_Policy_Wingform.pdf"
              target="_blank"
            >
              Cookie Policy
            </a>
          </div>
          <div className="cookies__buttons">
            <Button
              onClick={handleClick}
              type="secondary"
              size="s"
              className="transparent"
            >
              Accept
            </Button>
          </div>
        </div>
      </div>
    )
  )
}

export default CookiesNotification
