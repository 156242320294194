import React, { ReactNode, useState } from "react"
import "./Button.scss"
import Icon from "../Icon/Icon"

export type ButtonType = "primary" | "secondary" | "tertiary" | "link" | "icon" | "red"
export type ButtonSize = "xs" | "s" | "m" | "l"

export type IconPosition = "l" | "r"
export type IconType = "arrow" | "plus"

interface IButton {
    btnType?: "button" | "submit" | "reset"
    className?: string
    isDisabled?: boolean
    isLoading?: boolean
    onClick?: (event) => void
    type?: ButtonType
    size?: ButtonSize
    children?: ReactNode
    icon?: string
    iconPosition?: "left" | "right"
    // Не применяет width: 100%
    minimize?: boolean
    noPadding?: boolean
}

const Button: React.FC<IButton> = (props) => {
    const {
        btnType = "button",
        type = "primary",
        size = "m",
        className,
        isDisabled = false,
        isLoading,
        onClick,
        children,
        icon,
        iconPosition = "right",
        minimize,
        noPadding = false
    } = props

    // const [isClicked, setIsClicked] = useState<boolean>(false)

    const handleClick = (event) => {
        // setIsClicked((prev) => !prev)
        if (onClick && !isLoading) onClick(event)
    }

    return (
        <button
            className={`
            button
            ${size ? `button--${size}` : ""}
            ${type ? `button--${type}` : ""}
            ${className || ""}
            ${isLoading ? "button--loading" : ""}
            ${
                minimize !== undefined
                    ? minimize
                        ? "button--min"
                        : "button--max"
                    : "button--normal"
            }
            ${noPadding ? "button--no-padding" : ""}
            `}
            disabled={isDisabled}
            onClick={(event) => handleClick(event)}
            type={btnType}
        >
            <span>{children}</span>
            {icon || isLoading ? (
                <Icon
                    name={isLoading ? "loading-icon" : icon}
                    className={`button__icon button__icon--${iconPosition}`}
                    // isClicked={isClicked}
                    width={20}
                    height={20}
                />
            ) : null}
        </button>
    )
}

export default Button