import React from "react"
import { Link } from "react-router-dom"
import "./Header.scss"

interface IHeaderProps {
    scrolledDown: boolean
    landing?: boolean
}

const Header: React.FC<IHeaderProps> = (props) => {
    const { scrolledDown } = props
    const className: string =
        "header " + (scrolledDown ? "scrolled" : "unscrolled")

    return (
        <div className={className} id="header">
            <div className="container">
                <div className="header__inner">
                    <div className="header__logo">
                        <Link to="/">
                            <img
                                src={require("assets/images/Wingform.svg")}
                                alt="Wingform"
                                title="Home"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
