import Layout from "src/hoc/Layout"
import React, {
    useState,
    useEffect,
    useRef
} from "react"
import { PopupModal } from "react-calendly"

import "./Landing.scss"
import CookiesNotification from "src/components/CookiesNotification/CookiesNotification"
import Article from "src/components/Article/Article"
import SlideTitle from "src/components/SlideTitle/SlideTitle"
import SlideFooter from "src/components/SlideFooter/SlideFooter"
// import Modal from "react-modal"
import Modal from "src/components/Modal/Modal"

interface ISlide {
    title: string
    markup: React.ReactNode
}

interface ILandingSlider {
    slides: (currentSlide: number) => ISlide[]
    //slides: () => ISlide[]
    onPlayVideo: () => void
}

interface ILandingSlide {
    title: string
    className?: string
    isMain?: boolean
    currentSlide?: number
    isCurrent?: boolean
    openCalendly: any
    openVideo: any
    children: any
    hasModal?: boolean
}

const getAbsolutePosition = (el) => {
    let offsetY = 0
    let parent = null
    offsetY += el.offsetTop
    parent = el.parentElement
    while (parent.tagName !== "BODY") {
        offsetY += parent.offsetTop
        parent = parent.parentElement
    }
    return offsetY
}

const LandingSlide: React.FC<ILandingSlide> = (props) => {
    const {
        className,
        title,
        isMain,
        currentSlide,
        isCurrent,
        children,
        openCalendly,
        openVideo,
        hasModal = false
    } = props
    const slideRef = useRef(null)
    const slideTitleRef = useRef(null)
    const [isInView, setIsInView] = useState<number>(0)
    const headerRef = useRef(null)
    //0 - invisible, 1 - normal header, 2 - fixed header, 3 - bottom header

    useEffect(() => {
        const header = document.getElementById("header")
        headerRef.current = header
        const onScroll = () => {
            const headerOffset = slideTitleRef.current.offsetTop
            if (isMain)
                console.log("headerOffset", headerOffset)
            const slideTop = getAbsolutePosition(slideRef.current.parentElement) - header.clientHeight + headerOffset * (isMain ? 1 : 0)
            const slideBottom = slideTop + slideRef.current.clientHeight - slideTitleRef.current.clientHeight - headerOffset * (isMain ? 1 : 0) //header.clientHeight
            const windowTop = window.scrollY + header.clientHeight
            const windowBottom = window.innerHeight + windowTop

            /*if (title == "What we are")
            console.log('slide', {
              title,
              slideTop,
              slideBottom,
              windowTop,
              windowBottom
            })*/

            if (slideTop >= windowBottom) {
                setIsInView(0)
            } else {
                if (slideTop >= windowTop) {
                    setIsInView(1)
                } else {
                    if (slideBottom >= windowTop) {
                        setIsInView(2)
                    } else {
                        setIsInView(3)
                    }
                }
            }
        }

        window.addEventListener("scroll", onScroll)
        setTimeout(() => {
            onScroll()
        }, 50)
        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [])

    return (
        <div className={`slide--${className} slide`} ref={slideRef}>
            <div className={`slider-heading ${isMain && "slider-heading--main"}`}>
                <SlideTitle
                    currentSlide={currentSlide}
                    isCurrent={isCurrent}
                    fadable
                    isInView={isInView}
                    ref={slideTitleRef}
                >
                    {title}
                </SlideTitle>
            </div>
            <div className="slider-content">
                {children}
            </div>
            <SlideFooter
                openCalendly={openCalendly}
                hasModal={hasModal}
            />
        </div>
    )
}

const LandingSlider: React.FC<ILandingSlider> = (props) => {
    const { onPlayVideo } = props
    const slideScroll = 20
    const scrollDelay = 500

    const [intro, setIntro] = useState<boolean>(true)

    const [currentSlide, setCurrentSlide] = useState<number>(0)
    const [slideLock, setSlideLock] = useState<boolean>(false)
    const currentSlideRef = useRef({
        currentSlide: currentSlide,
        lock: slideLock,
        timer: null,
        wheelDelta: 0
    })

    const touchRef = useRef({
        touch: false,
        startY: null,
        lastDelta: 0
    })

    const updateCurrentSlide = (current: number, lock: boolean = true) => {
        setCurrentSlide(current)
        currentSlideRef.current.currentSlide = current
        if (currentSlideRef.current.timer)
            clearTimeout(currentSlideRef.current.timer)
        if (lock) {
            setSlideLock(true)
            currentSlideRef.current.lock = true
            currentSlideRef.current.timer = setTimeout(() => {
                setSlideLock(false)
                currentSlideRef.current.lock = false
            }, scrollDelay)
        }
    }

    const changeSlide = (e) => {
        if (window.innerWidth >= 1280) {
            if (e.wheelDelta) {
                if (
                    (e.wheelDelta < 0 &&
                        e.wheelDelta <= currentSlideRef.current.wheelDelta) ||
                    (e.wheelDelta > 0 && e.wheelDelta >= currentSlideRef.current.wheelDelta)
                ) {
                    handleSlideChange(e.wheelDelta < 0)
                }
                currentSlideRef.current.wheelDelta = e.wheelDelta
            }
            if (e.keyCode && (e.keyCode == 38 || e.keyCode == 40)) {
                handleSlideChange(e.keyCode == 40, false)
            }
        }
    }

    const touchStart = (e) => {
        touchRef.current.touch = true
        touchRef.current.startY = e.targetTouches[0].clientY
    }

    const touchMove = (e) => {
        if (touchRef.current.touch) {
            const delta = e.targetTouches[0].clientY - touchRef.current.startY
            //console.log('delta', delta)
            if (
                delta > slideScroll ||
                (delta < -slideScroll &&
                    Math.abs(delta) >= Math.abs(touchRef.current.lastDelta))
            ) {
                touchRef.current.touch = false
                handleSlideChange(!(delta > slideScroll), false)
            }
            touchRef.current.lastDelta = delta
        }
    }

    const touchEnd = (e) => {
        touchRef.current.touch = false
    }

    const handleSlideChange = (forward: boolean, lock: boolean = true) => {
        if (!currentSlideRef.current.lock) {
            //console.log('handleSlideChange', forward, new Date().getTime())
            if (forward) {
                updateCurrentSlide(
                    Math.min(currentSlideRef.current.currentSlide + 1, slidesCount - 1),
                    lock
                )
            } else {
                updateCurrentSlide(
                    Math.max(currentSlideRef.current.currentSlide - 1, 0),
                    lock
                )
            }
        }
    }

    const slides = props.slides(currentSlide)
    //console.log(window.location)
    const slidesCount =
        window?.location?.pathname === "/" ? slides.length : slides.length - 1

    useEffect(() => {
        const dlyr = setInterval(() => {
            currentSlideRef.current.wheelDelta =
                currentSlideRef.current.wheelDelta - 2
            touchRef.current.lastDelta = touchRef.current.lastDelta - 2
        }, 100)
        window.addEventListener("wheel", changeSlide)
        window.addEventListener("keydown", changeSlide)
        window.addEventListener("touchstart", touchStart)
        window.addEventListener("touchmove", touchMove)
        window.addEventListener("touchend", touchEnd)

        return () => {
            clearInterval(dlyr)
            window.removeEventListener("wheel", changeSlide)
            window.removeEventListener("keydown", changeSlide)
            window.removeEventListener("touchstart", touchStart)
            window.removeEventListener("touchmove", touchMove)
            window.removeEventListener("touchend", touchEnd)
        }
    }, [])

    useEffect(() => {
        if (intro && currentSlide == 0) {
            setIntro(false)
        }
    }, [currentSlide])

    return (
        <>
            <div className="slider-container">
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`slider__slide slider__slide--${index} ${
                            index == currentSlide ? "slider__slide--current" : ""
                        }`}
                    >
                        {slide.markup}
                    </div>
                ))}
            </div>
            <div className={`sidebar sidebar--${currentSlide === 0 ? "first-slide" : "not-first-slide"}`}>
                <nav className="sidebar__menu">
                    <ul className="sidebar__list">
                        {slides.map((slide, index) => {
                            return (
                                <li
                                    key={index}
                                    onClick={() => updateCurrentSlide(index, false)}
                                    className={`
                                        sidebar__item sidebar__item--${index}
                                        ${index == currentSlide ? "sidebar__item--current" : ""}
                                    `}
                                >
                                    <span>{slide.title}</span>
                                </li>
                            )
                        })}
                    </ul>
                </nav>
                {currentSlide === 7
                    ?
                    <div className={"sidebar__company-information"}>
                        <div className={"sidebar__company-name"}>
                            WINGFORM Inc.
                        </div>
                        <div>
                            1207 Delaware Ave #3093,
                            <br />
                            Wilmington, DE, US 19806
                        </div>
                    </div>
                    : null
                }
            </div>
        </>
    )
}

const Landing: React.FC = () => {
    const [videoPlaying, setVideoPlaying] = useState<boolean>(false)
    const [isCalendlyOpen, setIsCalendlyOpen] = useState<boolean>(false)

    const handlePlay = () => {
        setVideoPlaying(true)
    }

    return (
        <Layout>
            <div className="container landing-grid">
                <CookiesNotification />
                <LandingSlider
                    onPlayVideo={handlePlay}
                    slides={(currentSlide): ISlide[] => [
                        {
                            title: "",
                            markup: (
                                <LandingSlide
                                    isMain={true}
                                    className="main-slide"
                                    title="Aircraft trading. Reinvented"
                                    isCurrent={window.innerWidth >= 1280 ? currentSlide == 0 : false}
                                    openCalendly={setIsCalendlyOpen}
                                    openVideo={handlePlay}
                                    currentSlide={currentSlide}
                                >
                                    <p className="landing-text">
                                        The ultimate platform for aircraft sales transactions
                                    </p>
                                </LandingSlide>
                            )
                        },
                        {
                            title: "Our services",
                            markup: (
                                <LandingSlide
                                    title="Our services"
                                    className="audience"
                                    isCurrent={window.innerWidth >= 1280 ? currentSlide == 1 : false}
                                    openCalendly={setIsCalendlyOpen}
                                    openVideo={handlePlay}
                                    currentSlide={currentSlide}
                                    hasModal={true}
                                >
                                    <p>
                                        Wingform is a comprehensive digital assistant designed for aircraft brokers and
                                        aviation service providers. Streamlining the entire buying and selling process
                                        in one secure platform. With Wingform, you can manage every aspect of your
                                        transaction, including communication with third-party service providers, in a
                                        single place, ensuring a seamless experience.
                                        <br />
                                        <br />
                                        Developed by aviation
                                        professionals and legal experts, Wingform protects your transactions from fraud
                                        and identity theft while ensuring compliance with industry regulations. Whether
                                        you're a first-time buyer, seller, or a broker, Wingform provides the tools to
                                        complete transactions efficiently, securely, and privately, saving you time and
                                        money.
                                    </p>
                                    <ul className="landing__list">
                                        <li className="landing__item">
                                            Company & Team Management
                                        </li>
                                        <li className="landing__item">
                                            Built-in Compliance Verification
                                        </li>
                                        <li className="landing__item">Online Access to Financing, Inspections,
                                            Management & Appraisals
                                        </li>
                                        <li className="landing__item">
                                            Inventory Management
                                        </li>
                                        <li className="landing__item">
                                            Built-in Escrow Services Origination & Execution
                                        </li>
                                        <li className="landing__item">
                                            Transaction Planning & Execution
                                        </li>
                                        <li className="landing__item">
                                            Marketplace & Confidential Online Negotiations
                                        </li>
                                        <li className="landing__item">
                                            Automatically Drafted Legal Documents & Digital Signatures
                                        </li>
                                        <li className="landing__item">
                                            Online Communication
                                        </li>
                                    </ul>
                                </LandingSlide>
                            )
                        },
                        {
                            title: "Features & Benefits",
                            markup: (
                                <LandingSlide
                                    title="Key features and benefits"
                                    className="features"
                                    isCurrent={window.innerWidth >= 1280 ? currentSlide == 2 : false}
                                    openCalendly={setIsCalendlyOpen}
                                    openVideo={handlePlay}
                                    currentSlide={currentSlide}
                                >

                                    <Article title="Accomplishment">
                                        <p>
                                            Successfully close more transactions with greater accuracy and confidence,
                                            leveraging tools that ensure each deal aligns with both regulatory standards
                                            and client expectations. Wingform enables you to exceed your operational
                                            targets by facilitating smoother and faster closures.
                                        </p>
                                    </Article>
                                    <Article title="Time & Cost Savings">
                                        <p>
                                            Cut down on both the time and expenses typically associated with aircraft
                                            transactions by utilizing Wingform's all-in-one platform. From automated
                                            document management to direct communication channels, reduce the need for
                                            external resources and minimize costs while speeding up the transaction
                                            process.
                                        </p>
                                    </Article>
                                    <Article title="Efficiency & Control">
                                        <p>
                                            Manage all aspects of your transactions from a single dashboard that offers
                                            complete oversight. Wingform’s integrated system ensures that you maintain
                                            full control over every phase of the deal, from initial contact to final
                                            agreement, enhancing operational fluency and decision-making precision.
                                        </p>
                                    </Article>
                                    <Article title="Privacy & Security">
                                        <p>
                                            Wingform ensures the highest levels of privacy and security by enabling
                                            encrypted communications and secure document exchanges. Protect your
                                            sensitive data and maintain confidentiality throughout the transaction
                                            process, preventing unauthorized access and data breaches with our robust
                                            security protocols.
                                        </p>
                                    </Article>
                                </LandingSlide>
                            )
                        },
                        {
                            title: "Users",
                            markup: (
                                <LandingSlide
                                    title="Who needs us"
                                    className="audience"
                                    isCurrent={window.innerWidth >= 1280 ? currentSlide == 3 : false}
                                    openCalendly={setIsCalendlyOpen}
                                    openVideo={handlePlay}
                                    currentSlide={currentSlide}
                                >

                                    <Article title="Aircraft Brokers and Transaction Stakeholders">
                                        <p>
                                            Brokers can transact independently, ensuring data confidentiality, control
                                            over the process, and costs. Information about the seller and the asset is
                                            only shared with verified prospects, enhancing privacy and transaction
                                            security.
                                        </p>
                                    </Article>
                                    <Article title="Established dealers and brokers">
                                        <p>
                                            Use Wingform to streamline transactions, save time and costs. Manage your
                                            global sales team on one platform. Enhance your reputation with efficient,
                                            secure services, building trust and satisfaction in your business.
                                        </p>
                                    </Article>
                                    <Article title="Individual Brokers">
                                        <p>
                                            Efficiently handle client transactions with a comprehensive deal flow. Our
                                            platform eliminates third-party reliance, maximizing profit margins. Save
                                            time, focus on generating more opportunities, and close additional deals.
                                        </p>
                                    </Article>
                                    <Article title="Third Party Services">
                                        <p>
                                            Third-party service providers prefer transacting within a secure platform
                                            like Wingform because it centralizes communication and document sharing,
                                            eliminating the inefficiency of managing multiple emails and reducing the
                                            risk of data breaches. By streamlining interactions and keeping everything
                                            in one secure location, they can work more efficiently while ensuring
                                            sensitive information remains protected.
                                        </p>
                                    </Article>
                                </LandingSlide>
                            )
                        },
                        {
                            title: "Transactions",
                            markup: (
                                <LandingSlide
                                    title="Streamlined transactions"
                                    className="audience"
                                    isCurrent={window.innerWidth >= 1280 ? currentSlide == 4 : false}
                                    openCalendly={setIsCalendlyOpen}
                                    openVideo={handlePlay}
                                    currentSlide={currentSlide}
                                >
                                    <p className="hide-it">
                                        Experience streamlined and transparent transactions on Wingform, where every
                                        step is meticulously designed for clarity and ease. From initial listing to
                                        final sale, our platform ensures a smooth progression with integrated tools for
                                        tracking, communication, and compliance, making every transaction efficient and
                                        straightforward.
                                    </p>
                                    <ul className="landing__list">
                                        <li className="landing__item">
                                            Pre-drafted Modifiable Deal Flows
                                        </li>
                                        <li className="landing__item">
                                            Automatically Forecasted Timelines
                                        </li>
                                        <li className="landing__item">Step-by-Step Execution of Deals</li>
                                        <li className="landing__item">
                                            Automated Legal Documents and Digital Signatures
                                        </li>
                                        <li className="landing__item">
                                            Terms & Conditions modification
                                        </li>
                                        <li className="landing__item">
                                            Notifications on Actions and Timelines
                                        </li>
                                        <li className="landing__item">
                                            Multiple Transactions at the Same Time
                                        </li>
                                    </ul>
                                </LandingSlide>
                            )
                        },
                        {
                            title: "Legal compliance",
                            markup: (
                                <LandingSlide
                                    title="Legal compliance"
                                    className="complience"
                                    isCurrent={window.innerWidth >= 1280 ? currentSlide == 5 : false}
                                    openCalendly={setIsCalendlyOpen}
                                    openVideo={handlePlay}
                                    currentSlide={currentSlide}
                                >
                                    <p>
                                        Wingform is dedicated to ensuring legal compliance in
                                        every aspect of private aircraft transactions.
                                    </p>
                                    <div className="article__container">
                                        <Article title="Compliance checks">
                                            <p>
                                                By integrating KYC/KYB/AML verification, Wingform
                                                verifies the eligibility of all participants and
                                                prevents money-laundering violations, ensuring that
                                                only trustworthy contractors engage in transactions.
                                            </p>
                                        </Article>
                                        <Article title="Pre-drafted legal documents">
                                            <p>
                                                The platform automatically generates all necessary legal and
                                                transactional documents drafted by leading aviation lawyers. You may
                                                also plug in your law firm or use your own templates. Wingform
                                                digitalises your templates, which significantly reduces the risk for
                                                errors and saving you a lot of time.
                                            </p>
                                        </Article>
                                        <Article title="Digital signatures">
                                            <p>
                                                All documents are signed right on the platform with
                                                legally-binding eSignatures in accordance with eIDAS
                                                standards.
                                            </p>
                                        </Article>
                                    </div>
                                </LandingSlide>
                            )
                        },
                        {
                            title: "Security",
                            markup: (
                                <LandingSlide
                                    title="Security"
                                    className="security"
                                    isCurrent={window.innerWidth >= 1280 ? currentSlide == 6 : false}
                                    openCalendly={setIsCalendlyOpen}
                                    openVideo={handlePlay}
                                    currentSlide={currentSlide}
                                >
                                    <Article title="Authenticity">
                                        <p>
                                            In addition to compliance checks, the platform acquires,
                                            validates, and discloses information concerning the
                                            counterparty's credibility, authorization, and aircraft
                                            ownership rights, allowing relevant parties to confirm
                                            these details.
                                        </p>
                                    </Article>
                                    <Article title="Transactions">
                                        <p>
                                            Digital technologies applied on the platform automate
                                            deal execution and monitor the fulfillment of
                                            obligations, reducing the risk of fraud and enhancing
                                            the security of transactions.
                                        </p>
                                    </Article>
                                    <Article title="Data Protection">
                                        <p>
                                            Cryptography technologies provide a secure and
                                            tamper-proof digital ledger for data and documents
                                            storage in distributed manner, ensuring data integrity
                                            and immutability.
                                        </p>
                                    </Article>
                                    <Article title="Assets">
                                        <p>
                                            Built-in assets management instruments and integration
                                            with reliable closing solutions providers protect your
                                            funds during transactions by securely holding and
                                            transferring assets between parties, mitigating the risk
                                            of financial loss.
                                        </p>
                                    </Article>
                                </LandingSlide>
                            )
                        },
                        {
                            title: "Who we are",
                            markup: (
                                <LandingSlide
                                    title="Who we are"
                                    className="security"
                                    isCurrent={window.innerWidth >= 1280 ? currentSlide == 7 : false}
                                    openCalendly={setIsCalendlyOpen}
                                    openVideo={handlePlay}
                                    currentSlide={currentSlide}
                                >
                                    <Article title={""}>
                                        <div className={"person"}>
                                            <div className={"person__image"}>
                                                <img src="assets/images/filippov.png" alt="" />
                                            </div>
                                            <div className={"person__description"}>
                                                <div className={"person__name"}>Sergei Filippov</div>
                                                <div className={"person__job-title"}>CEO & co-founder</div>
                                                <div className={"person__socials"}>
                                                    <a
                                                        href="https://www.linkedin.com/in/sergei-filippov"
                                                        target={"_blank"}
                                                    >
                                                        <img src="assets/images/linkedin.svg" alt="" />
                                                    </a>
                                                    <a href="mailto:sergei@wingform.com">
                                                        <img src="assets/images/mail.svg" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Article>
                                    <Article title={""}>
                                        <div className={"person"}>
                                            <div className={"person__image"}>
                                                <img src="assets/images/gratton.png" alt="" />
                                            </div>
                                            <div className={"person__description"}>
                                                <div className={"person__name"}>Andrew Gratton</div>
                                                <div className={"person__job-title"}>COO & co-founder</div>
                                                <div className={"person__socials"}>
                                                    <a
                                                        href="https://www.linkedin.com/in/0andrew-gratton/"
                                                        target={"_blank"}
                                                    >
                                                        <img src="assets/images/linkedin.svg" alt="" />
                                                    </a>
                                                    <a href="mailto:andrew@wingform.com">
                                                        <img src="assets/images/mail.svg" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Article>
                                    <Article title={""}>
                                        <div className={"person"}>
                                            <div className={"person__image"}>
                                                <img src="assets/images/cordier.png" alt="" />
                                            </div>
                                            <div className={"person__description"}>
                                                <div className={"person__name"}>Dustin Cordier</div>
                                                <div className={"person__job-title"}>Advisor to the Board</div>
                                                <div className={"person__socials"}>
                                                    <a
                                                        href="https://www.linkedin.com/in/dustin-cordier-bio/"
                                                        target={"_blank"}
                                                    >
                                                        <img src="assets/images/linkedin.svg" alt="" />
                                                    </a>
                                                    <a href="mailto:dustin@stepzerocoaching.com">
                                                        <img src="assets/images/mail.svg" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Article>
                                    {/*<Article title={""}>*/}
                                    {/*    <div className={"person"}>*/}
                                    {/*        <div className={"person__image"}>*/}
                                    {/*            <img src="assets/images/chapman.png" alt="" />*/}
                                    {/*        </div>*/}
                                    {/*        <div className={"person__description"}>*/}
                                    {/*            <div className={"person__name"}>Tom Chapman</div>*/}
                                    {/*            <div className={"person__job-title"}>CRO</div>*/}
                                    {/*            <div className={"person__socials"}>*/}
                                    {/*                <a*/}
                                    {/*                    href="https://www.linkedin.com/in/candltomchapman2019/"*/}
                                    {/*                    target={"_blank"}*/}
                                    {/*                >*/}
                                    {/*                    <img src="assets/images/linkedin.svg" alt="" />*/}
                                    {/*                </a>*/}
                                    {/*                <a href="mailto:tom@wingform.com">*/}
                                    {/*                    <img src="assets/images/mail.svg" alt="" />*/}
                                    {/*                </a>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</Article>*/}
                                </LandingSlide>
                            )
                        },
                        {
                            title: "Partners & groups",
                            markup: (
                                <LandingSlide
                                    title="Partners & groups"
                                    className="partners"
                                    isCurrent={window.innerWidth >= 1280 ? currentSlide == 7 : false}
                                    openCalendly={setIsCalendlyOpen}
                                    openVideo={handlePlay}
                                    currentSlide={currentSlide}
                                >
                                    <div className={"partners__logo"}>
                                        <img src="assets/images/nbaa.png" alt="" />
                                        <img src="assets/images/glada.png" alt="" />
                                        <img src="assets/images/nafa.png" alt="" />
                                        <img src="assets/images/iats.png" alt="" />
                                        <img src="assets/images/jlc.png" alt="" />
                                        <img src="assets/images/jetstream.png" alt="" />
                                        <img src="assets/images/aic.png" alt="" />
                                        <img src="assets/images/mitsui.png" alt="" />
                                        <img src="assets/images/join jet.png" alt="" />
                                        <img src="assets/images/crew chiefs.png" alt="" />
                                        <img src="assets/images/aci.png" alt="" />
                                        <img src="assets/images/axis.png" alt="" />
                                    </div>
                                </LandingSlide>
                            )
                        }
                    ]}
                />
                <Modal
                    modalIsOpen={videoPlaying}
                    onRequestClose={() => setVideoPlaying(false)}
                >
                    <video src="../../assets/video/join-video.mp4" controls />
                </Modal>
                <PopupModal
                    url="https://calendly.com/andrew-wingform/demo-call-about-wingform?hide_gdpr_banner=1&primary_color=001253"
                    onModalClose={() => setIsCalendlyOpen(false)}
                    open={isCalendlyOpen}
                    rootElement={document.getElementById("app")}
                />
            </div>
        </Layout>
    )
}

export default Landing
