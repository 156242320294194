import React, { useState, useRef, useEffect, forwardRef } from "react"
import ReactGA from "react-ga4"

interface ISlideTitle {
  children: string
  animated?: boolean
  fadable?: boolean
  isInView?: number
  currentSlide?: number
  isCurrent?: boolean
  ref: any
}
interface IPoint {
  x: number
  y: number
}

const getAbsolutePosition = (node: any) => {
  var p: IPoint = { x: 0, y: 0 }
  if (node) {
    p.x = node.offsetLeft
    p.y = node.offsetTop
    while (node.offsetParent) {
      p.x = p.x + node?.offsetParent?.offsetLeft
      p.y = p.y + node?.offsetParent?.offsetTop
      if (node == document.getElementsByTagName("body")[0]) {
        break
      } else {
        node = node.offsetParent
      }
    }
  }

  return p
}

interface IChar {
  char: string
  index: number
}

interface IChars {
  chars: IChar[]
}

const getCharMap = (text: string) => {
  const res = []
  let index = 0
  text.split(" ").forEach((word, wordIndex) => {
    const wordItem:IChars = {
      chars: []
    }
    word.split("").forEach((char) => {
      wordItem.chars.push({
        char: char,
        index: index
      })
      index++
    })
    res.push(wordItem)
  })
  return res
}

const SlideTitle: React.FC<ISlideTitle> = forwardRef((props, ref: any) => {
  const travelTime = 50
  const travelDelay = 500
  const travelOutScreenDelta = 200
  const { children, animated = true, currentSlide,isCurrent, fadable, isInView } = props
  const titleRef = useRef(null)
  const charRef = useRef([])
  const [placeholderHeight, setPlaceHolderHeight] = useState<number>(33)
  const pepelazRef = useRef<HTMLDivElement>()
  const length = children.length
  const charMap = getCharMap(children)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const [offsetHeader, setOffsetHeader] = useState<IPoint>()
  const [flyPath, setFlyPath] = useState<IPoint>()
  const [offsetX, setOffsetX] = useState<number>(-1000)
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  const animationRef = useRef<any>({
    from: 0,
    to: 0,
    current: 0,
    //time: 0,
    start: 0,
    callback: null
  })

  const animate = (time) => {
    //if (animationRef.current.time != null) {
    if (animationRef.current.start == 0) {
      animationRef.current.start = time
    } else {
      const deltaTime = time - animationRef.current.start
      
      if (deltaTime > travelDelay) {
        const newPosition =
          animationRef.current.from +
          ((windowWidth +
            50 /*animationRef.current.to*/ -
            animationRef.current.from) *
            (deltaTime - travelDelay)) /
            ((travelTime * windowWidth) / 30)

        let cnt = 0
        charRef.current.forEach((span, i) => {
          if (+span.offsetLeft < +newPosition) {
            charRef.current[i].className = "passed"
            cnt++
            if (i == length - 1 && pepelazRef?.current?.className && fadable) {
              pepelazRef.current.className = "fademe pplz"
            }
          }
        })
        //console.log('PassedChars', cnt)
        //console.log('animation', deltaTime, animationRef.current, newPosition)
        animationRef.current.current = newPosition
        pepelazRef.current.style.left = `${newPosition}px`
        //console.log('newPosition', newPosition)
        setOffsetX(newPosition)
      }
    }
    //}
    if (
      animationRef.current.current <
      windowWidth + 50 /*animationRef.current.to*/
    ) {
      animationRef.current.callback = requestAnimationFrame(animate)
    } else {
      animationRef.current.current = windowWidth + 50 //animationRef.current.to
    }
  }


  const playAnimation = () => {
    const firstChar = charRef.current[0]
    const lastChar = charRef.current[charRef.current.length - 1]

    //console.log('magic!', {firstChar, lastChar})
    if (firstChar && lastChar) {
      const firstCharOffset = getAbsolutePosition(firstChar)
      const lastCharOffset = getAbsolutePosition(lastChar)
      const flyPathNew = {
        x: lastCharOffset.x + lastChar.clientWidth + 50 - firstCharOffset.x,
        y: lastCharOffset.y
      }
      const offsetHeaderNew = {
        x: firstCharOffset.x + travelOutScreenDelta,
        y: firstCharOffset.y
      }
      setFlyPath(flyPathNew)
      setOffsetHeader(offsetHeaderNew)
      charRef.current.forEach((char) => (char.className = ""))

      if (offsetHeaderNew && flyPathNew ) {
        //titleRef.current = isInView
        //console.log('Animate X', -offsetHeader.x, flyPath.x)

        animationRef.current.from = -flyPathNew.x
        animationRef.current.to = flyPathNew.x
        animationRef.current.current = -flyPathNew.x
        pepelazRef.current.className = "pplz"
        pepelazRef.current.style.left = `-{offsetHeaderNew.x}px`

        setOffsetX((a) => -flyPathNew.x)
        cancelAnimationFrame(animationRef.current.callback)
        animationRef.current.callback = requestAnimationFrame(animate)
      }
    }
    //}

    animationRef.current = {
      from: 0,
      to: 0,
      current: 0,
      //time: 0,
      start: 0,
      callback: null
    }
    onResize()
  }

  useEffect(() => {
    if (isPlaying) {
      //console.log('magic!', {charRefLength: charRef.current.length, length})
      //if (charRef.current.length == length) {
      //console.log('play >>', children)
      playAnimation()

    }
  }, [isPlaying])

  /*useEffect(() => {
    //console.log('useEffect', {offsetHeader, flyPath, isInView})

    if (offsetHeader && flyPath ) {
      //titleRef.current = isInView
      //console.log('Animate X', -offsetHeader.x, flyPath.x)

      animationRef.current.from = -offsetHeader.x
      animationRef.current.to = flyPath.x
      animationRef.current.current = -offsetHeader.x
      pepelazRef.current.className = "pplz"
      pepelazRef.current.style.left = `-{offsetHeader.x}px`

      setOffsetX((a) => -offsetHeader.x)
      //animationRef.current.start = new Date()
      animationRef.current.callback = requestAnimationFrame(animate)
    }
    return () => cancelAnimationFrame(animationRef.current.callback)
  }, [flyPath, offsetHeader/, isInView, currentSlide])*/

  const onResize = () => {
    setWindowWidth((w) => window.innerWidth)
    setPlaceHolderHeight(titleRef?.current?.clientHeight)
  }

  useEffect(() => {
    //console.log('play', children, {isInView, isPlaying, currentSlide, isCurrent})
    if (isCurrent) {
      //setIsPlaying(true)
      playAnimation()
    } else {
      if (isInView == 1 || isInView == 2) {
        setIsPlaying(true)
      } else {
        setIsPlaying(false)
      }
    }
    /*if (isInView == 1 || isInView == 2 || isCurrent ) {
      setIsPlaying(true)
    } else {
      setIsPlaying(false)
    }*/

    //playAnimation()
  }, [isInView, isCurrent, currentSlide])

  useEffect(() => {
    if (
      window.location.pathname !== "/landing1" &&
      window.location.pathname !== "/landing3"
    ) {
      //remove ReactGA от /landing1 for test purpose
      ReactGA.initialize("G-ZTLRWQKV1B")
    }

    //console.log('useEffect', children)

    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
      cancelAnimationFrame(animationRef.current.callback)
    }
    onResize()
  }, [])

  /*useEffect(() => {
    if (flyPath?.x > 100) {
      //pepelazRef
    }
  }, [flyPath])*/

  return (
      <div className="h1-placeholder" style={{minHeight: `${placeholderHeight}px`}} ref={ref}>
      <h1
        className={`${animated ? "airplaned" : "just-title"} inView-${isInView}`}
        ref={titleRef}
      >
        {
          charMap.map((word) => 
            <>
              <span className="word">
                {
                  word.chars.map((char) => 
                    <span
                      key={char.index}
                      ref={(el) => {
                        charRef.current[char.index] = el
                      }}
                    >
                      {char.char}
                    </span>
                  )
                }
              </span>
              &#32; 
            </>
          )
        }
        <div
          ref={pepelazRef}
          style={{ left: `${offsetX}px` }}
          className="pplz"
        >
          <img
            src="/assets/images/landing.webp"
            alt="Wingform plane"
          />
        </div>
      </h1>
      </div>
  )
})

export default SlideTitle
