import React, {
    useEffect
} from "react"
import { Route, Switch } from "react-router-dom"
import Landing from "./pages/Landing/Landing"
import ReactGA from "react-ga4"

/*console.log("DONENV LOADED", {
  "process.env.BACKEND_URL": process.env.BACKEND_URL,
  "process.env.SUMSUB_BACKEND": process.env.SUMSUB_BACKEND,
  "process.env.DOC_CONS_DEAL": process.env.DOC_CONS_DEAL
})*/

const App = () => {

    useEffect(() => {
        ReactGA.initialize([
            {
                trackingId: "G-ZTLRWQKV1B"
            }
        ])
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
        console.log('GA initialized!')
    }, [])
  // useEffect(() => {
  //   !isLanding &&
  //     (function () {
  //       window.Intercom("boot", {
  //         app_id: "alnasi7q",
  //         name: `${profile?.firstName} ${profile?.lastName}`,
  //         email: profile?.email,
  //         created_at: new Date().toTimeString()
  //       })
  //       window.Intercom("update", {
  //         name: `${profile?.firstName} ${profile?.lastName}`
  //       })
  //       console.debug("profile", profile)
  //     })()
  // }, [profile])

  return (
    <Switch>
      <Route
        path="*"
        component={Landing}
      />
    </Switch>
  )
}

export default App
