import React, {
    useEffect, useState
} from "react"
import Button from "../Button/Button"
import ReactGA from "react-ga4"
import Modal from "src/components/Modal/Modal"
import { countries } from "src/components/SlideFooter/constants"
import "./SlideFooter.scss"

interface ISlideFooter {
    openCalendly: React.Dispatch<React.SetStateAction<any>>
    hasModal: boolean
}

const SlideFooter: React.FC<ISlideFooter> = (props) => {
    const { openCalendly, hasModal } = props

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    useEffect(() => {
        ReactGA.initialize([
            {
                trackingId: "G-ZTLRWQKV1B"
            }
        ])
    }, [])

    const handleGoToApp = () => {
        ReactGA.event("app_button")
        ReactGA.event({
            category: "button",
            action: "click",
            label: "Go to APP"
        })
        window.open("https://app.wingform.com")
    }

    const handleBookDemo = () => {
        ReactGA.event("book_button")
        ReactGA.event({
            category: "button",
            action: "click",
            label: "Book demo"
        })
        openCalendly(true)
    }

    return (
        <div className={`slider-container__footer ${hasModal ? "flex--column" : null}`}>
            {
                hasModal
                    ?
                    <Button
                        minimize={true}
                        onClick={() => setIsModalOpen(true)}
                        type={"link"}
                        noPadding={true}
                    >
                        Countries of Operations
                    </Button>
                    :
                    null
            }
            <div className={"flex--row"}>
                <Button
                    onClick={handleGoToApp}
                    type="secondary"
                    icon="button-arrow-icon"
                >
                    Go to app
                </Button>
                <Button
                    onClick={handleBookDemo}
                    type="secondary"
                    icon="calendar-icon"
                >
                    Book demo
                </Button>
            </div>
            <Modal
                onRequestClose={() => setIsModalOpen(false)}
                modalIsOpen={isModalOpen}
                title={"Countries of Operations"}
            >
                <ul className={"slider-container__footer__countries"}>
                    {countries.map((country: string, index) => {
                        return <li key={index}>{country}</li>
                    })}
                </ul>
            </Modal>
        </div>
    )
}

export default SlideFooter
