import React, { useState } from "react"
import Header from "src/components/Header/Header"

interface ILayout {
    children: JSX.Element | JSX.Element[]
}

const Layout: React.FC<ILayout> = React.memo(function Layout({
                                                                 children
                                                             }: ILayout) {
    const [scrolledDown, setScrolledDown] = useState<boolean>(false)

    return (
        <div className="container">
            <div className="wrapper path-landing">
                <Header scrolledDown={scrolledDown} />
                <main
                    className="main"
                    role="main"
                >
                    {children}
                </main>
            </div>
        </div>
    )
})

export default Layout
