import React from "react"
import "./Article.scss"

interface IArticle {
    title: string
    children: React.ReactNode
}

const Article: React.FC<IArticle> = (props) => {
    const { title, children } = props

    return (
        <div className="article">
            <div className="article__title">{title}</div>
            <div className="article__content">{children}</div>
        </div>
    )
}

export default Article
